var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"orderList-con-box list-con-box",class:`list-con-box${_vm.mallOrStoreGoodsFlag}`},[_vm._l((_vm.newStoreList),function(mallOrStore,index){return _c('ul',{key:index,class:['list-con', index < _vm.newStoreList.length - 1 ? 'list-con__pre' : '']},[(mallOrStore.mall_name && !_vm.isXtraOrPrimeOrder)?_c('div',{staticClass:"mall-name-wapper"},[_c('div',{staticClass:"mall-name",domProps:{"innerHTML":_vm._s(mallOrStore.mall_name)}}),_vm._v(" "),(_vm.mallNamePopoverText)?_c('sui_icon_doubt_12px_2',{directives:[{name:"expose",rawName:"v-expose",value:({
          id: '1-20-1-128',
          data: {
            order_no:_vm.order.billno
          },
        }),expression:"{\n          id: '1-20-1-128',\n          data: {\n            order_no:order.billno\n          },\n        }"}],staticClass:"mall-icon-margin",attrs:{"color":"#959595","size":"12px"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.reportNoticeDa.apply(null, arguments)}}}):_vm._e()],1):_vm._e(),_vm._v(" "),_vm._l((mallOrStore.storeList),function(store,storeIndex){return _c('li',{key:storeIndex,class:[
        'list-con-li',
        storeIndex == mallOrStore.storeList.length - 1 ? 'list-con-li__last' : ''
      ]},[(store.mall_name && !_vm.isXtraOrPrimeOrder)?_c('div',{staticClass:"mall-name-wapper"},[_c('div',{staticClass:"mall-name",domProps:{"innerHTML":_vm._s(store.mall_name)}}),_vm._v(" "),(_vm.mallNamePopoverText)?_c('sui_icon_doubt_12px_2',{directives:[{name:"expose",rawName:"v-expose",value:({
            id: '1-20-1-128',
            data: {
              order_no:_vm.order.billno
            },
          }),expression:"{\n            id: '1-20-1-128',\n            data: {\n              order_no:order.billno\n            },\n          }"}],staticClass:"mall-icon-margin",attrs:{"color":"#959595","size":"12px"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.reportNoticeDa.apply(null, arguments)}}}):_vm._e()],1):_vm._e(),_vm._v(" "),(store.store_name && !_vm.isXtraOrPrimeOrder)?_c('div',{staticClass:"store-name",on:{"click":function($event){$event.stopPropagation();return _vm.jumpToStoreIndex(store)}}},[_c('i',{staticClass:"logo-default suiiconfont sui_icon_store_15px"}),_vm._v(" "),_c('span',{directives:[{name:"expose",rawName:"v-expose",value:({
            id: '1-20-1-46',
            data: { store_code: store.store_code, business_model: store.business_model }
          }),expression:"{\n            id: '1-20-1-46',\n            data: { store_code: store.store_code, business_model: store.business_model }\n          }"}],staticClass:"store-text",domProps:{"innerHTML":_vm._s(store.store_name)}}),_vm._v(" "),(!!store.business_model && _vm.isOpenShopUrl)?_c('i',{staticClass:"iconfont icon-m_forward_competition_40px store-icon"}):_vm._e()]):_vm._e(),_vm._v(" "),_vm._l((store.orderGoodsList),function(product,pIndex){return _c('div',{key:pIndex,staticClass:"list-con-img"},[_c('CropImageContainer',{attrs:{"img-src":_vm.transformImg({ img: product.product.goods_img }),"fixed-ratio":"3-4"}})],1)})],2)})],2)}),_vm._v(" "),(_vm.overTwoStore)?_c('span',{directives:[{name:"expose",rawName:"v-expose",value:({ id: '1-20-1-48', data: {} }),expression:"{ id: '1-20-1-48', data: {} }"}],staticClass:"view-more-item",on:{"click":function($event){$event.stopPropagation();return _vm.jumpToDetail.apply(null, arguments)}}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_18635))]),_vm._v(" "),_c('i',{staticClass:"iconfont icon-m_forward_competition_40px icon"})]):_vm._e(),_vm._v(" "),_c('cccMallNameNoticeDialog',{attrs:{"language":_vm.language,"visible":_vm.isShowMallNotice,"content":_vm.mallNamePopoverText?_vm.mallNamePopoverText:''},on:{"closeMallNmaeDialog":function($event){_vm.isShowMallNotice = false}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }