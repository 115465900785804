<template>
  <div class="pay-now-tag">
    <div
      v-if="payNowLabel.type === 'discount'"
      class="discount-tag"
    >
      <Icon
        name="sui_icon_save_8px"
        size="12px"
        color="#fa6338"
      />
      <span class="discount-tag__text">{{ payNowLabel.text }}</span>
    </div>
    <span
      v-else-if="payNowLabel.type === 'success'"
      class="success-tag"
    >
      {{ payNowLabel.text }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'

const props = defineProps({
  language: {
    type: Object,
    default: () => {}
  },
  payNowLabelList: {
    type: Array,
    default: () => []
  }
})

const payNowLabel = computed(() => {
  return props.payNowLabelList[0] || {}
})
</script>

<style lang="less" scoped>
.pay-now-tag {
  display: inline-flex;
  font-size: 10px;
  font-weight: 700;

  .discount-tag {
    display: inline-flex;
    align-items: center;
    padding: 1px 2px;
    border-radius: 4/75rem;
    color: #fa6338;
    border: 1px solid rgba(250, 99, 56, 0.6);
    background-color: #fff8eb;

    &__text {
      margin-left: 4/75rem;
    }
  }

  .success-tag {
    color: @sui_color_safety;
  }
}
</style>
