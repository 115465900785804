var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-list-card"},[(_vm.showTrackCard)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
      id: '1-20-1-62',
      data: _vm.logisticsTrackAnalysis
    }),expression:"{\n      id: '1-20-1-62',\n      data: logisticsTrackAnalysis\n    }"},{name:"tap",rawName:"v-tap",value:({
      id: '1-20-1-63',
      data: _vm.logisticsTrackAnalysis
    }),expression:"{\n      id: '1-20-1-63',\n      data: logisticsTrackAnalysis\n    }"}],staticClass:"order-list-track",on:{"click":function($event){$event.stopPropagation();return _vm.handelTrackClick($event)}}},[_c('div',{staticClass:"order-list-track__left"},[_c('i',{staticClass:"suiiconfont",class:_vm.mallStatusData.icon})]),_vm._v(" "),_c('div',{staticClass:"order-list-track__content"},[_c('div',{staticClass:"order-list-track__content__title"},[_c('span',{staticClass:"order-list-track__content__title-status"},[_vm._v("\n          "+_vm._s(_vm.mallStatusData.text)+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"order-list-track__content__title-time"},[_vm._v("\n          "+_vm._s(_vm.handlerDate(_vm.order.track_info.timestamp))+"\n          "+_vm._s(_vm.handleShortTime(_vm.order.track_info.timestamp))+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"order-list-track__content__info",domProps:{"innerHTML":_vm._s(_vm.trackContent)}})]),_vm._v(" "),_vm._m(0)]):_vm._e(),_vm._v(" "),(_vm.showTransportTime)?_c('div',{staticClass:"order-list-card__transport-ime",class:{ 'is-show-gutter': _vm.showTrackCard },domProps:{"innerHTML":_vm._s(_vm.getTransportTimeDesc())}}):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-list-track__right"},[_c('i',{staticClass:"suiiconfont sui_icon_more_right_16px"})])
}]

export { render, staticRenderFns }