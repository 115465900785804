<template>
  <div
    class="orderList-con-box list-con-box"
    :class="`list-con-box${mallOrStoreGoodsFlag}`"
  >
    <ul
      v-for="(mallOrStore, index) in newStoreList"
      :key="index"
      :class="['list-con', index < newStoreList.length - 1 ? 'list-con__pre' : '']"
    >
      <!-- mall名称 -->
      <div 
        v-if="mallOrStore.mall_name && !isXtraOrPrimeOrder"
        class="mall-name-wapper"
      >
        <div
          class="mall-name"
          v-html="mallOrStore.mall_name"
        >
        </div>
        <sui_icon_doubt_12px_2 
          v-if="mallNamePopoverText"
          v-expose="{
            id: '1-20-1-128',
            data: {
              order_no:order.billno
            },
          }"
          class="mall-icon-margin"
          color="#959595"
          size="12px"  
          @click.native.stop="reportNoticeDa"
        />
      </div>
      <li
        v-for="(store, storeIndex) in mallOrStore.storeList"
        :key="storeIndex"  
        :class="[
          'list-con-li',
          storeIndex == mallOrStore.storeList.length - 1 ? 'list-con-li__last' : ''
        ]"
      >
        <!-- mall名称 -->
        <div
          v-if="store.mall_name && !isXtraOrPrimeOrder" 
          class="mall-name-wapper"
        >
          <div
            class="mall-name"
            v-html="store.mall_name"
          ></div>
          <sui_icon_doubt_12px_2 
            v-if="mallNamePopoverText"
            v-expose="{
              id: '1-20-1-128',
              data: {
                order_no:order.billno
              },
            }"
            class="mall-icon-margin"
            color="#959595"
            size="12px"
            @click.native.stop="reportNoticeDa"
          />
        </div>
        <!-- store名称 -->
        <div
          v-if="store.store_name && !isXtraOrPrimeOrder"
          class="store-name"
          @click.stop="jumpToStoreIndex(store)"
        >
          <i class="logo-default suiiconfont sui_icon_store_15px"></i>
          <span
            v-expose="{
              id: '1-20-1-46',
              data: { store_code: store.store_code, business_model: store.business_model }
            }"
            class="store-text"
            v-html="store.store_name"
          >
          </span>
          <i
            v-if="!!store.business_model && isOpenShopUrl"
            class="iconfont icon-m_forward_competition_40px store-icon"
          ></i>
        </div>
        <div
          v-for="(product, pIndex) in store.orderGoodsList"
          :key="pIndex"
          class="list-con-img"
        >
          <!-- <img
            :src="GB_cssRight ? transformImg({ img: product.product.goods_img }) : ''"
            :data-src="GB_cssRight ? '' : transformImg({ img: product.product.goods_img })"
            :class="[GB_cssRight ? '' : 'lazyload']"
          /> -->
          <CropImageContainer
            :img-src="transformImg({ img: product.product.goods_img })"
            fixed-ratio="3-4"
          />
        </div>
      </li>
    </ul>
    <span
      v-if="overTwoStore"
      v-expose="{ id: '1-20-1-48', data: {} }"
      class="view-more-item"
      @click.stop="jumpToDetail"
    >
      <span class="text">{{ language.SHEIN_KEY_PWA_18635 }}</span>
      <i class="iconfont icon-m_forward_competition_40px icon"></i>
    </span>
    <cccMallNameNoticeDialog 
      :language="language"
      :visible="isShowMallNotice"
      :content="mallNamePopoverText?mallNamePopoverText:''"
      @closeMallNmaeDialog="isShowMallNotice = false"
    />
  </div>
</template>
<script>
import schttp from 'public/src/services/schttp'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { transformImg } from '@shein/common-function'
import orderLogic from 'public/src/pages/common/orderLogic'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import cccMallNameNoticeDialog from 'public/src/pages/user/child_pages/orders/common/cccMallNameNoticeDialog.vue'
import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'
import { sui_icon_doubt_12px_2 } from '@shein-aidc/icon-vue2'

daEventCenter.addSubscriber({ modulecode: '1-20-1' })

const { langPath, GB_cssRight } = gbCommonInfo
export default {
  name: 'PlatFormItem',
  directives: { expose },
  components: {
    CropImageContainer,
    sui_icon_doubt_12px_2,
    cccMallNameNoticeDialog
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    isOpenShopUrl: {
      type: Boolean,
      default: true
    },
    orderStatusTextList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      GB_cssRight,
      isShowMallNotice: false,
      isReportMallNameDa: false
    }
  },
  computed: {
    // 是否所有的mall都有mallName
    allMallHasName() {
      let { mall_list = [] } = this.order || {}
      let existMallList = mall_list?.filter(mall => !!mall.mall_name)
      return existMallList?.length == mall_list?.length
    },
    // 是否所有的store都有store_name
    allStoreHasName() {
      const orderGoodsList = this.initGoodsList
      let existStoreList = orderGoodsList?.filter(goodsObj => !!goodsObj.store_name)
      return existStoreList?.length == orderGoodsList?.length
    },
    // 是否是单mall
    isSingleMall() {
      return this.order?.is_multi_mall != 1
    },
    // 会否是支付前订单
    isUnPaidOrder() {
      let { orderStatus } = this.order || {}
      let unPaidOrderStatus = [0, 12, 13]
      return unPaidOrderStatus.includes(+orderStatus)
    },
    // 获取商品展示的数据结构
    mallOrStoreGoodsFlag() {
      // 1 商品聚合 2 店铺+商品 3 mall+商品 4 mall+店铺+商品
      if (this.isSingleMall) {
        return this.allStoreHasName ? 2 : 1
      }
      if (this.isUnPaidOrder) {
        return this.allMallHasName ? 3 : 1
      } else {
        if (this.allMallHasName) {
          return this.allStoreHasName ? 4 : 3
        } else {
          return this.allStoreHasName ? 2 : 1
        }
      }
    },
    rootGoodsList() {
      return this.order?.orderGoodsList || []
    },
    // 是否为虚拟商品单独购订单, 如果是的话，虚拟商品单独展示一行, 不区分mall跟店铺信息
    isXtraOrPrimeOrder() {
      let { order_type } = this.order || {}
      // order_type 5 付费会员单独购
      // order_type 6 超省卡单独购
      return [5, 6]?.includes(+order_type)
    },
    // 订单是否包含虚拟商品[随单购场景]
    isXtraOrPrimeGoods() {
      // is_prime_goods true 付费会员商品
      // is_xtra_goods true 超省卡商品
      return this.rootGoodsList.filter(item => item.is_prime_goods || item.is_xtra_goods)
    },
    isXtraOrPrimeSplit() {
      return this.rootGoodsList.length > 1 && this.isXtraOrPrimeGoods?.length
    },
    initGoodsList() {
      // 未支付包含付费会员虚拟商品 单独一列展示
      const initGoodsList = this.rootGoodsList
      if (this.isXtraOrPrimeSplit) {
        return initGoodsList.filter(item => !(item.is_prime_goods || item.is_xtra_goods))
      }
      return initGoodsList
    },
    storeList() {
      let flag = this.mallOrStoreGoodsFlag

      // 商品聚合展示
      if ([1].includes(flag)) {
        return [
          {
            storeList: [
              { ...this.order, orderGoodsList: orderLogic.sortOrderList(this.rootGoodsList) }
            ]
          }
        ]
      }
      if ([2, 3].includes(flag))
        return [{ storeList: this.groupStoreByStoreCode(flag == 2 ? 'store_code' : 'mall_code') }]
      return this.groupMallOrStoreByCode()
    },
    formatStoreList() {
      // 判断是否展示超过两个store或者mall
      let flag = this.mallOrStoreGoodsFlag

      if ([1].includes(flag)) return this.storeList
      if ([2, 3].includes(flag)) {
        let mallOrStoreArr = this.storeList?.reduce(
          (totalArr, curArr) => (totalArr = [...totalArr, ...curArr.storeList]),
          []
        )
        return [{ storeList: mallOrStoreArr?.slice(0, 2) }]
      }
      return this.storeList.slice(0, 2)
    },
    newStoreList() {
      let flag = this.mallOrStoreGoodsFlag

      if (flag == 1) return this.formatStoreList
      if (this.isXtraOrPrimeSplit && flag != 1) {
        let isXtraOrPrimeGoods = [
          ...(this.isXtraOrPrimeGoods?.filter(item => item.is_xtra_goods) || []),
          ...(this.isXtraOrPrimeGoods?.filter(item => item.is_prime_goods) || [])
        ]
        return [
          {
            storeList: [
              { orderGoodsList: isXtraOrPrimeGoods, orderGoodsSum: isXtraOrPrimeGoods?.length }
            ]
          }
        ]
          .concat(this.formatStoreList)
          .slice(0, 2)
      }
      return this.formatStoreList
    },
    overTwoStore() {
      // 判断是否展示超过两个store或者mall
      let flag = this.mallOrStoreGoodsFlag
      if ([2, 3].includes(flag)) {
        let mallOrStoreArr = this.storeList?.reduce(
          (totalArr, curArr) => (totalArr = [...totalArr, ...curArr.storeList]),
          []
        )
        if (this.isXtraOrPrimeSplit) return mallOrStoreArr?.length > 1
        return mallOrStoreArr?.length > 2
      }
      return this.storeList.length > 2
    },
    mallNamePopoverText() {
      //ccc 返回 和商品状态满足下面条件 展示小问号
      let matchObj = orderLogic.getOrderStatusTextByOrderList(this.orderStatusTextList, {
        ...this.order,
        order_or_package_status: this.order.order_detail_status
      })
      const handle = this.order.orderGoodsList.flat(Infinity)
      return (matchObj?.mallTraceText && !handle?.every(i=>[5, 7, 16, 57, 20, 74, 75].includes(Number(i.status)))) ? matchObj?.mallTraceText : false
    }
  },
  methods: {
    transformImg,
    reportNoticeDa(){
      if(!this.isReportMallNameDa) {
        daEventCenter.triggerNotice({
          daId: '1-20-1-129',
          extraData: {
            order_no: this.order.billno,
          }
        })
      }
      this.isReportMallNameDa = true
      this.isShowMallNotice = true
    },
    groupStoreByStoreCode(mall_store_code) {
      let { mall_list = [] } = this.order || {}
      const orderGoodsList = this.initGoodsList || []
      let shopGoodsInfo = {}
      if (mall_store_code == 'store_code') {
        orderGoodsList?.forEach(orderGoods => {
          if (shopGoodsInfo[orderGoods[mall_store_code]]) {
            shopGoodsInfo[orderGoods[mall_store_code]]?.orderGoodsList?.push(orderGoods)
          } else {
            shopGoodsInfo[orderGoods[mall_store_code]] = {
              orderGoodsList: [orderGoods],
              store_name: orderGoods.store_name,
              store_code: orderGoods.store_code,
              business_model: orderGoods.business_model,
              store_logo: orderGoods.store_logo
            }
          }
        })
      } else {
        mall_list?.forEach(mall => {
          if (!shopGoodsInfo[mall.mall_code]) {
            shopGoodsInfo[mall.mall_code] = {
              mall_code: mall.mall_code,
              mall_name: mall.mall_name,
              orderGoodsList: []
            }
          }
        })
        orderGoodsList?.forEach(orderGoods => {
          shopGoodsInfo?.[orderGoods[mall_store_code]]?.orderGoodsList?.push(orderGoods)
        })
      }
      return Object.values(shopGoodsInfo) || []
    },
    groupMallOrStoreByCode() {
      let { mall_list = [] } = this.order
      const orderGoodsList = this.initGoodsList
      let mallGoodsInfo = {}
      mall_list?.forEach(mall => {
        if (!mallGoodsInfo[mall.mall_code]) {
          mallGoodsInfo[mall.mall_code] = {
            mall_code: mall.mall_code,
            mall_name: mall.mall_name,
            storeList: []
          }
        }
      })
      let mallValues = Object.values(mallGoodsInfo) || []
      // 把所有商品按照店铺code分组
      mallValues.forEach(mallGoods => {
        orderGoodsList.forEach(store => {
          if (store.mall_code == mallGoods.mall_code) {
            let isExistCodeList =
              mallGoods?.storeList?.filter(list => list.store_code == store.store_code) || []
            !isExistCodeList?.length &&
              mallGoods.storeList.push({
                store_code: store.store_code,
                store_name: store.store_name,
                business_model: store.business_model,
                store_logo: store.store_logo,
                orderGoodsList: []
              })
          }
          mallGoods?.storeList?.forEach(storeObj => {
            let tempNewGoodsList = JSON.parse(JSON.stringify(orderGoodsList))
            storeObj.orderGoodsList = tempNewGoodsList?.filter(goodsObj => {
              return goodsObj.store_code == storeObj.store_code
            })
          })
        })
      })
      return mallValues
    },
    async getStoreSelects(params) {
      let res = await schttp({
        url: `/api/store/matchStoreJumpUrl/get?storeCode=${params}`
      })
      return res?.links || {}
    },
    async jumpToStoreIndex({ business_model, store_code, orderGoodsList = [] }) {
      if (business_model != 1 || !this.isOpenShopUrl) return
      daEventCenter.triggerNotice({
        daId: '1-20-1-45',
        extraData: {
          shop_code: store_code
        }
      })
      const goods_ids = []
      const cate_ids = []
      orderGoodsList?.forEach(item => {
        // 数据提取
        if (item?.goods_id) {
          goods_ids.push(item.goods_id)
        }
        if (item?.product?.cat_id) {
          cate_ids.push(item.product.cat_id)
        }
      })
      let href_tail = '&rule_poskey=CartShopItemList' //web默认值
      if (goods_ids.length > 0) {
        // 数组去重，goods_ids字符串拼接
        const str = [...new Set(goods_ids)].join(',')
        href_tail += `&goods_ids=${str}`
      }
      if (cate_ids.length > 0) {
        // 数组去重，cate_ids字符串拼接
        const str = [...new Set(cate_ids)].join(',')
        href_tail += `&cate_ids=${str}`
      }
      const storeInfo = await this.getStoreSelects(store_code)
      if (storeInfo?.[store_code]) {
        if (href_tail) {
          location.href = `${langPath}${storeInfo?.[store_code]}${href_tail}`
        } else {
          location.href = `${langPath}${storeInfo?.[store_code]}`
        }
      }
    },
    jumpToDetail() {
      daEventCenter.triggerNotice({
        daId: '1-20-1-47'
      })
      location.href = `${langPath}/user/orders/detail/${this.order.billno}`
      // location.href = `${langPath}/orders/detail?billno=${this.order.billno}`
    }
  }
}
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.list-con {
  padding: 0.3rem;
  .border-dpr(border-bottom,2px,#e5e5e5);
  background: #fff;
  > li {
    .flexbox();
    flex-flow: row wrap;
    padding: 24/75rem 0;
    &:last-child {
      padding: 24/75rem 0 0 0;
    }
    &:first-child {
      padding: 0;
    }
    > .list-href {
      .flexbox();
      text-decoration: none;
      position: relative;
    }
    .left-inner {
      width: 2rem;
      height: 2.64rem;
      .margin-r(0.2rem);
      position: relative;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
      .mask_ctn {
        position: absolute;
        top: 0;
        .left(0);
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
      }
      .soldOut-box {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 50%;
        .left(0);
        transform: translate(0, -50%);
        color: #fff !important; /* stylelint-disable-line declaration-no-important */
        i {
          .font-dpr(60px);
          line-height: normal;
          display: inline-block;
        }
        .text {
          line-height: normal;
          text-transform: capitalize;
          .font-dpr(24px);
        }
      }
    }
    .inner {
      width: 7rem;
      .operator_box {
        margin-top: 0.213rem;
        .exchange_tips {
          .font-dpr(24px);
          color: rgba(
            255,
            65,
            28,
            1
          ) !important; /* stylelint-disable-line declaration-no-important */
        }
        .exchange_btn {
          margin-top: 0.1rem;
          a {
            display: inline-block;
            .font-dpr(24px);
            padding: 0.1rem 0.2rem;
            border: 1px solid rgba(229, 229, 229, 1);
            text-decoration: none;
            color: #222222 !important; /* stylelint-disable-line declaration-no-important */
            & when (@IS_RW) {
              height: 0.7rem;
              line-height: 0.7rem;
              border-radius: 0.35rem;
              padding: 0 0.2rem;
            }
            & + a {
              .margin-l(0.2rem);
            }
          }
        }
      }
      > p {
        line-height: 1.5;
        .font-dpr(24px);
      }
      .size {
        margin-top: 0.213rem;
        color: #666;
        > em {
          display: block;
        }
      }
      .price {
        color: #222;
        > a {
          color: #222;
          display: block;
        }
      }
      .tip-spec-content {
        .mshe-no-border {
          width: 4rem;
          font-weight: 400;
        }
      }
      .tip-spec-wrap {
        display: inline-block;
        position: relative;
        font-weight: 400;
        p {
          padding: 0.1rem;
          color: #999;
          border: 1px solid #e5e5e5;
          position: absolute;
          bottom: 0.6rem;
          .right(0);
          background: #fff;
          width: 6rem;
          z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
          .font-dpr(24px);
        }
        [class*='iconfont'] {
          color: #999999;
        }
        &.detail {
          p {
            position: absolute;
            top: 50%;
            bottom: auto;
            .right(0.64rem);
            transform: translateY(-50%);
            padding: 0.213rem 0.426rem;
            color: #666;
            .font-dpr(28px);
            width: 8.48rem;
            box-shadow: 0 2px 16px 4px rgba(0, 0, 0, 0.16);
            border-radius: 1px;
            &:after {
              content: '';
              position: absolute;
              top: 50%;
              .right(-0.32rem);
              transform: translateY(-50%);
              width: 0;
              height: 0;
              border: 0.16rem solid transparent;
              border-left: 0.16rem solid #fff;
            }
          }
        }
      }
      .struct {
        align-items: flex-start;
        .struct-goods-name {
          .font-dpr(24px);
          color: #666666;
          font-weight: 400;
          .margin-r(0.11rem);
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .struct-price {
          .txt-r();
          span {
            .font-dpr(24px);
            color: #222;
            font-weight: bold;
            &.struct-price__dis {
              color: @sui_color_discount;
              & when (@IS_RW) {
                color: @color_brand;
              }
            }
            &.struct-price-prime {
              color: #c96e3f;
            }
          }
          del {
            .font-dpr(20px);
            color: @sui_color_gray_light1;
            font-weight: 400;
          }
        }
      }
      .size-info {
        align-items: flex-start;
        margin-top: 10/75rem;
        .size-inf {
          width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
      .goods-qs-tag {
        margin-top: 8/75rem;
        display: inline-block;
        color: #198055;
        .font-dpr(20px);
        background: #f5fcfb;
        padding: 3/75rem 8/75rem;
      }
    }
    .exchange-goods {
      .size {
        opacity: 0.3;
        color: #666666 !important; /* stylelint-disable-line declaration-no-important */
      }
      .price {
        opacity: 0.3;
        color: #666666 !important; /* stylelint-disable-line declaration-no-important */
        > a {
          opacity: 0.3;
          color: #666666 !important; /* stylelint-disable-line declaration-no-important */
        }
      }
      .struct {
        .struct-goods-name {
          opacity: 0.3;
          color: #666666 !important; /* stylelint-disable-line declaration-no-important */
        }
        .struct-price {
          span {
            opacity: 0.3;
            color: #666666 !important; /* stylelint-disable-line declaration-no-important */
          }
          del {
            opacity: 0.3;
            color: #666666 !important; /* stylelint-disable-line declaration-no-important */
          }
        }
      }
      .tip-spec-wrap {
        p {
          opacity: 0.3;
          color: #666666 !important; /* stylelint-disable-line declaration-no-important */
        }
      }
    }
  }
  .mall-name-wapper {
    display: flex;
    align-items: center;
    width: 100%;
  } 
  .mall-icon-margin {
    margin-left: 8/75rem;
  }
}
.list-con-box.orderList-con-box {
  .list-con {
    padding: 0.32rem !important; /* stylelint-disable-line declaration-no-important */
  }
  .list-con__pre {
    padding: 0.32rem 0.32rem 0 0.32rem !important; /* stylelint-disable-line declaration-no-important */
  }
  .list-con-li {
    padding: 0 0 0.32rem 0 !important; /* stylelint-disable-line declaration-no-important */
    &.list-con-li__last {
      padding: 0 !important; /* stylelint-disable-line declaration-no-important */
    }
  }
  .mall-name {
    text-align: left;
    .text-overflow();
    font-weight: 700;
    .font-dpr(28px);
    text-transform: capitalize;
    white-space: pre;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #222;
  }
  .store-name {
    display: flex;
    text-align: left;
    .text-overflow();
    font-weight: bold;
    display: flex;
    align-items: center;
    .store-logo {
      width: 24/75rem;
      height: 24/75rem;
      display: inline-block;
      .margin-r(8/75rem);
      border-radius: 50%;
    }
    .logo-default {
      font-weight: initial;
      display: inline-block;
      .margin-r(8/75rem);
      .font-dpr(28px);
    }
    .store-text {
      .font-dpr(24px);
      display: inline-block;
      text-transform: capitalize;
      white-space: pre;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .store-icon {
      .font-dpr(24px);
      .padding-l(8/75rem);
      color: #222;
      display: inline-block;
    }
  }
  .view-more-item {
    .font-dpr(24px);
    padding: 0 0 0.32rem 0;
    margin: 0 0.32rem;
    text-align: center;
    text-transform: capitalize;
    display: block;
    display: flex;
    align-items: center;
    color: #222;
    justify-content: center;
    .icon {
      .font-dpr(24px);
    }
  }
}
.list-con-box2 {
  .store-name {
    margin-bottom: 0.32rem;
  }
}
.list-con-box3 {
  .mall-name-wapper {
    margin-bottom: 0.32rem;
  }
}
.list-con-box4 {
  .mall-name-wapper {
    margin-bottom: 8/75rem;
  }
  .store-name {
    margin-bottom: 0.32rem;
  }
}
</style>
